import {inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Redirect} from "./interface";

@Injectable({providedIn: "root"})
export class RedirectBrowserService implements Redirect {
    private router = inject(Router);

    async _301(url: string): Promise<boolean> {
        return this.router.navigateByUrl(url);
    }

    async _404() {
        return await this.router.navigateByUrl("/404", {skipLocationChange: true});
    }

    async _410() {
        return await this.router.navigateByUrl("/410", {skipLocationChange: true});
    }

    async _200() {
        return await this.router.navigateByUrl("/");
    }
}
