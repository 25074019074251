import {APP_INITIALIZER} from "@angular/core";
import {LoadCanonicalService} from "@common/core/load/canonical.service";
import {CityLoadService} from "@core/service/city/city-load.service";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {ParametersService} from "@core/service/parameters";
import {ResizeService} from "@core/service/resize/resize.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {MobileService} from "@core/service/mobile";
import {PlatformService} from "@common/core/service/platform";
import {Resize2Service} from "@core/service/resize2/resize2.service";

/**
 * Только для общих сервисов которые нужно загрузить при инициализации приложения.
 */
export const APP_INITIALIZER_GENERAL = [
    {
        provide: APP_INITIALIZER,
        useFactory: (_deviceTokenService: DeviceTokenService) => () => _deviceTokenService.set(),
        deps: [DeviceTokenService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (_loadService: CityLoadService) => () => _loadService.load(),
        deps: [CityLoadService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (loadCanonicalService: LoadCanonicalService) => () => loadCanonicalService.load(),
        deps: [LoadCanonicalService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (_parametersService: ParametersService) => () => _parametersService.load(),
        deps: [ParametersService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (_resizeService: ResizeService) => () => _resizeService.load(),
        deps: [ResizeService, BreakpointObserver, MobileService, PlatformService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (_resizeService: Resize2Service) => () => _resizeService.load(),
        deps: [Resize2Service, BreakpointObserver, MobileService, PlatformService],
        multi: true,
    },
];
