import {inject, Injectable, signal} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";

@Injectable({
    providedIn: "root",
})
export class LoadingCommonState {
    private state = signal<boolean>(false);
    private platform = inject(PlatformService);

    start() {
        if (this.platform.browser) {
            this.state.set(true);
        }
    }

    stop() {
        if (this.platform.browser) {
            this.state.set(false);
        }
    }

    status() {
        return this.state.asReadonly();
    }
}
