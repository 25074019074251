import {inject, Injectable} from "@angular/core";
import {ApiItemsFavoritesIdsInGet} from "./interface";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ApiItemsFavoritesIdsService {
    private httpClient = inject(HttpClient);

    /**
     * @returns {Observable<ApiItemsFavoritesIdsInGet>}
     */
    get(): Observable<ApiItemsFavoritesIdsInGet> {
        return this.httpClient.get<ApiItemsFavoritesIdsInGet>("ma/site/v1/items/favorites/ids", {withCredentials: true});
    }
}
