import {Injectable} from "@angular/core";
import {ActivatedRoute, Params} from "@angular/router";
import {RouteData} from "@common/model/route-data";

export interface Route {
    path: string;
    params: Params;
    data: RouteData;
}

@Injectable({
    providedIn: "root",
})
export class RouteService {
    getChildMostRoute(_route: ActivatedRoute): Route {
        let route = _route.snapshot;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const allPathsJoined = route.pathFromRoot.map((s) => s.url.map((u) => u.path).join("/")).join("/");

        return {
            path: allPathsJoined,
            params: route.params,
            data: route.data,
        };
    }

    getAllMostRoute(_route: ActivatedRoute): Route {
        let route = _route.snapshot;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const allPathsJoined = route.pathFromRoot.map((s) => s.url.map((u) => u.path).join("/")).join("/");

        return {
            path: allPathsJoined,
            params: route.params,
            data: route.data,
        };
    }
}
