import {inject, Inject, Injectable, NgZone} from "@angular/core";
import {LoggerService} from "@common/core/service/logger";
import {ConfigService} from "@common/core/service/config";
import find from "lodash-es/find";
import set from "lodash-es/set";
import forEach from "lodash-es/forEach";
import {NavigationEnd, Router} from "@angular/router";
import {LazyScriptService} from "@common/core/service/lazy-script";
import {YandexSettings} from "@core/service/yandex-metrika/interface";
import {DOCUMENT} from "@angular/common";
import {MetaTagService} from "@core/service/meta-tag";
import {CityService} from "@core/service/city";
import {filter, take} from "rxjs/operators";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {Observable} from "rxjs/internal/Observable";

declare const Ya: any;
declare const window: any;

@Injectable({
    providedIn: "root",
})
export class YandexMetrikaService {
    private logger: LoggerService = new LoggerService();
    private _dataLayer: any;
    private config: YandexSettings;

    /**
     * true - загружен уже
     * null - не загружен еще
     *
     * @private
     */
    public downloadScriptStatus: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Yandex Metrika объект
     */
    public objYa: any;

    private ngZone = inject(NgZone);

    constructor(
        private _router: Router,
        @Inject(DOCUMENT) private _document: Document,
        private _lazyScript: LazyScriptService,
        private _metaTagService: MetaTagService,
        private _cityService: CityService,
        private _config: ConfigService,
    ) {
        this.config = this._config.get("ya");
    }

    /**
     * Запуск
     *
     */
    public start() {
        const url = "https://mc.yandex.ru/metrika/tag.js";
        const settings = {
            id: this.config.id,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            ecommerce: "dataLayer_ya",
            triggerEvent: true,
        };

        this._lazyScript
            .load(url, true)
            .then((resLoad) => {
                if (resLoad && Ya && window["Ya"]) {
                    this.ngZone.runOutsideAngular(() => {
                        this.objYa = new Ya.Metrika2(settings);
                    });

                    this._dataLayer = window.dataLayer_ya;
                    if (!this._dataLayer) {
                        window["dataLayer_ya"] = this._dataLayer = [];
                    }
                    this.reachGoalByUrl();

                    this.city();
                    this.citySwitch();

                    this._metaTagService.title$.subscribe((res) => {
                        if (res.title) {
                            this.transition(res.title);
                        }
                    });
                    this.downloadScriptStatus.next(true);
                } else {
                    this.downloadScriptStatus.next(false);
                }
                return;
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.log("YM Error", e);
            });
    }

    /**
     * Переходы. Считаются только после установки title
     *
     * @param {string} title
     */
    transition(title: string) {
        try {
            this.objYa.hit(this._document.location.pathname, {
                title: title,
            });
        } catch (e) {
            this.logger.error("YaMetrikaService transition", e);
        }
    }

    // Цели по url
    reachGoalByUrl() {
        const goal = this.config["goal"].url;
        this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            forEach(goal, (v) => {
                if (event.url === v.url && event.url !== "/basket/success") {
                    try {
                        this.objYa.reachGoal(v.number);
                    } catch (e) {
                        this.logger.error("YaMetrikaService", e);
                    }
                }
            });
        });
    }

    // Цели по url
    reachGoalByUrlTest() {
        const goal = this.config["goal"].url;
        forEach(goal, (v) => {
            if (v.url === "/basket/success") {
                try {
                    this.objYa.reachGoal(v.number);
                } catch (e) {
                    this.logger.error("YaMetrikaService", e);
                }
            }
        });
    }

    // Цели по идентификаторам
    reachGoalByIdentifier(code: string) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                try {
                    const identifier = this.config["goal"].identifier;
                    const f = find(identifier, {code: code});
                    if (f) {
                        this.objYa.reachGoal(f.code);
                    }
                } catch (e) {
                    this.logger.error("YaMetrikaService", e);
                }
            });
    }

    ecommerce(action: string, item: any, count?: number) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                const itemEc = {
                    name: item.name,
                    price: item.price,
                    brand: item.brand_name || item?.brand || item?.brand?.name,
                    category: item.group_name || item?.category || item?.group?.name,
                    quantity: count,
                };
                const obj = {};
                switch (action) {
                    case "detail":
                        set(obj, "ecommerce.detail.products", [itemEc]);
                        this.atc(item.atc);
                        break;
                    case "add":
                        set(obj, "ecommerce.add.products", [itemEc]);
                        this.atc(item.atc);
                        break;
                    case "remove":
                        set(obj, "ecommerce.remove.products", [itemEc]);
                        break;
                    case "purchase":
                        if (item) {
                            const products = [];
                            item.items.forEach((v) => {
                                products.push({
                                    name: v.name,
                                    price: v.price,
                                    brand: v.brand_name || item?.brand || item?.brand?.name,
                                    category: v.group_name || item?.category || item?.group?.name,
                                    quantity: v.quantity,
                                });
                            });
                            set(obj, "ecommerce.purchase.actionField", {id: item.id, goal_id: this.config["actionField"]});
                            set(obj, "ecommerce.purchase.products", products);
                        }

                        break;
                }

                try {
                    if (item.brand_name) {
                        this.objYa.params({
                            Производитель: item.brand_name || item.brand,
                        });
                    }
                } catch (e) {
                    this.logger.error("YaMetrikaService", e);
                }

                if (obj) {
                    try {
                        this._dataLayer.push(obj);
                    } catch (e) {
                        this.logger.error("YaMetrikaService", e);
                    }
                }
            });
    }

    city(): void {
        this.objYa.params({
            Город: this._cityService.data.name,
        });
    }

    citySwitch(): void {
        let oldName: string = this._cityService.data.name;
        this._cityService.city$.subscribe((val) => {
            if (oldName !== val.name) {
                this.objYa.params({
                    "Пользователь изменил город": `${oldName} -> ${val.name}`,
                });
                oldName = val.name;
            }
        });
    }

    // Пользователь изменил вид аптек
    public changedLookPharmacies(type: number = 1) {
        let str = "Список -> Карта";
        if (type === 2) {
            str = "Карта -> Список";
        } else if (type === 3) {
            str = "Список (подсказка) -> Карта";
        }
        this.params({
            "Пользователь изменил вид аптек": str,
        });
        // #51966 > Нажатие на пункт в переключателе состояния Список / Карта (Переключение_состояния_экрана)
        this.reachGoal("GL_action_basket_order_stores_view", {type: str});
    }

    // Пользователь переключил город/область в оформление заказа
    public changedLookCityRegion(type: number = 1) {
        let str = "Регион -> Город";
        if (type === 2) {
            str = "Город -> Регион";
        }
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                this.objYa.params({
                    "Пользователь изменил фильтр аптек": str,
                });
            });
    }

    public atc(_obj: object) {
        if (_obj) {
            this.downloadScriptStatus
                .pipe(filter((res) => res === true))
                .pipe(take(1))
                .subscribe(() => {
                    this.objYa.params({atc: _obj});
                });
        }
    }

    public target(code: string) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                try {
                    this.objYa.reachGoal(code);
                } catch (e) {
                    this.logger.error("YaMetrikaService", e);
                }
            });
    }

    public getClientID(): Observable<string> {
        return new Observable((sub) => {
            this.downloadScriptStatus
                .pipe(filter((res) => res !== null))
                .pipe(take(1))
                .subscribe((res) => {
                    sub.next(res ? this.objYa.getClientID() : "");
                });
        });
    }

    public setUserID(deviceToken: string) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                this.objYa.setUserID(deviceToken);
                this.objYa.params({deviceToken: deviceToken});
            });
    }

    // Показ пруфа на детальной товара
    public proofShown(id: number) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                this.objYa.params({
                    "Показан пруф": id.toString(),
                });
            });
    }

    // Показ детальной товара с рецептом
    public recipeDetailShown(id: number) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                this.objYa.params({
                    "Просмотр товара по рецепту": id.toString(),
                });
            });
    }

    public sendError(error) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                this.objYa.params({
                    debug: error,
                });
            });
    }

    /**
     * Отправка параметров визита
     * @param obj
     */
    public params(obj: object) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                this.objYa.params(obj);
            });
    }

    /**
     * Отправка параметров пользователя
     * @param obj
     */
    public userParams(obj: object) {
        this.downloadScriptStatus
            .pipe(filter((res) => res === true))
            .pipe(take(1))
            .subscribe(() => {
                this.objYa.userParams(obj);
            });
    }

    /**
     * Отправляет событие на пряму в яндекс
     *
     * @param code
     * @param params
     */
    public reachGoal(code: string | number, params?: object) {
        this.downloadScriptStatus
            .pipe(
                filter((res) => res === true),
                take(1),
            )
            .subscribe(() => this.objYa.reachGoal(code, params));
    }
}
