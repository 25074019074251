@switch (data().status) {
    @case ("empty") {
        <ng-container *ngTemplateOutlet="tag"></ng-container>
        <ng-container *ngTemplateOutlet="history"></ng-container>
    }
    @case ("fount") {
        <ng-container *ngTemplateOutlet="list"></ng-container>
    }
    @case ("not_fount") {
        <ng-container *ngTemplateOutlet="tag"></ng-container>
    }
}
<ng-template #tag>
    <div class="tags">
        <div class="tags__title">
            <b>Поможем подобрать препараты</b>
        </div>
        <div class="tags__list">
            @for (elem of data()?.queries; track elem.id; let index = $index) {
                <a (click)="onClickTagColor(elem)" routerLink="/search" [queryParams]="{q: elem.query}">
                    <ma-ui-sticker-color class="sticker-color_text" [color]="index | colorRandom" [text]="elem.query">
                    </ma-ui-sticker-color>
                </a>
            }
        </div>
    </div>
</ng-template>
<ng-template #list>
    @for (elem of data()?.products | slice: 0 : 3; track elem.filter_code) {
        <a class="elem" (click)="onClickProduct(elem)" routerLink="/{{ cityCode }}/{{ elem.code }}/{{ elem.filter_code }}">
            <ma-elem-product [name]="elem.name" [img]="elem.images" [groups]="elem.groups_string" />
        </a>
    }
    @if (data()?.groups?.length > 0 || data()?.tags?.length > 0) {
        <div class="elem title">
            <b>Категории</b>
        </div>
        @for (tag of data()?.tags; track tag.id) {
            <a class="elem" (click)="onClickTag(tag)" [routerLink]="['/', 'tags', tag.group_code, tag.code]">
                <ma-elem-item [name]="tag.name" />
            </a>
        }
        @for (group of data()?.groups | slice: 0 : 3; track group.id) {
            <a class="elem" (click)="onClickGroup(group)" [routerLink]="['/', cityCode, 'catalog', group.code]">
                <ma-elem-item [name]="group.name" />
            </a>
        }
    }

    @if (data()?.phrases?.length > 0) {
        @for (phrase of data()?.phrases; track phrase) {
            <a class="elem" routerLink="/search" [queryParams]="{q: phrase}">
                <ma-elem-item>Искать «{{ phrase }}»</ma-elem-item>
            </a>
        }
    }
</ng-template>
<ng-template #history>
    @for (elem of data()?.history(); track elem) {
        <a class="elem" (click)="onClickHistory(elem)" routerLink="/search" [queryParams]="{q: elem}">
            <ma-elem-history [elem]="elem"></ma-elem-history>
        </a>
    }
    @if (data()?.history()?.length > 0) {
        <a class="elem clear-history link link_underline link_red" (click)="clearHistory()"> Очистить историю поиска </a>
    }
</ng-template>
