import {inject, Injectable, signal} from "@angular/core";
import {StorageFavorites, StorageFavoritesItems} from "@common/shared/service/favorites/storage";
import {filter} from "rxjs/operators";
import {UserService} from "@common/core/service/user";
import {Subject, Subscription} from "rxjs";
import {ApiItemsFavoritesIdsUpdateInPost} from "@common/shared/service/api/items/favorites/ids/update";

@Injectable({
    providedIn: "root",
})
export class FavoritesService {
    /**
     * Список товаров в избранном
     *
     * @private
     */
    private list = new Map<number, boolean>();

    /**
     * Хранилище избранного
     *
     * @private
     */
    private storageFavorites = inject(StorageFavorites);

    /**
     * Сервис пользователя
     *
     * @private
     */
    private userService = inject(UserService);

    /**
     * Подписка на выход из аккаунта
     * @private
     */
    private logout$ = new Subscription();

    /**
     * Подписка на авторизацию
     * @private
     */
    private isAuth$ = new Subscription();

    /**
     * Событие обновления избранного
     */
    public update$ = new Subject<{id: number; status: "del" | "add"}>();

    /**
     * Ссылка на страницу избранного
     */
    public linkPage = signal("/favorites");

    /**
     * Получение данных при загрузке
     */
    public init() {
        this.getStorage();
        this.syncFavoritesItemsApi();

        this.isAuth$ = this.userService.isAuth$.subscribe((isAuth) => {
            this.linkPage.set(isAuth ? "/lk/favorites" : "/favorites");
        });
        this.logout$ = this.userService.logout$.pipe(filter((res) => res === true)).subscribe(() => {
            this.clear();
        });
    }

    /**
     * Уничтожение подписок
     */
    public destroy() {
        this.logout$.unsubscribe();
        this.isAuth$.unsubscribe();
    }

    /**
     * Получение данных из LocalStorage
     */
    public getStorage(): void {
        this.storageFavorites.storage.forEach(({id, is}) => {
            this.list.set(id, is);
        });
    }

    /**
     * Проверка товара в избранном
     *
     * @param id
     */
    public is(id: number): boolean {
        return this.list.has(id);
    }

    /**
     * Запись в избранное
     *
     * @param ids
     * @param isSync
     */
    public set(ids: number[], isSync: boolean = false): void {
        if (ids) {
            //Запись в память
            ids.forEach((id) => {
                this.list.set(id, isSync);
                this.update$.next({id, status: "add"});
            });

            //Запись в localStorage
            this.storageFavorites.storage = this.mapToArray();

            //Запись в API
            if (!isSync) {
                const list = ids.map((id) => {
                    return {id, is_deleted: false};
                });
                this.setApi(list);
            }
        }
    }

    /**
     * Удаление из избранного
     *
     * @param ids
     * @param isSync
     */
    public delete(ids: number[], isSync: boolean = true): void {
        if (ids) {
            ids.forEach((id) => {
                this.list.delete(id);
                this.update$.next({id, status: "del"});
            });

            this.storageFavorites.storage = this.mapToArray();
            if (isSync) {
                const list = ids.map((id) => {
                    return {id, is_deleted: true};
                });
                this.setApi(list);
            }
        }
    }

    /**
     * Очистка избранного
     */
    public clear(): void {
        this.list.clear();
        this.storageFavorites.storage = [];
    }

    public link() {
        return this.linkPage.asReadonly();
    }

    /**
     * Получение списка товаров избранного для авторизованного пользователя
     * между устройствами
     */
    public syncFavoritesItemsApi() {
        return this.storageFavorites.getItemsIdApi().subscribe((serverFavorites) => {
            // Синхронизация с сервером, если в localStorage есть ID, которых нет на сервере

            // От сервера на клиент
            const serverToLocal = serverFavorites.filter((id) => !this.list.has(id));

            // От клиента на сервер
            const localToServer = this.mapToArray().filter((value) => {
                return !serverFavorites.includes(value.id);
            });

            if (localToServer.length > 0) {
                this.setApi(
                    localToServer.map(({id}) => {
                        return {
                            id,
                            is_deleted: false,
                        };
                    }),
                );
            }

            if (serverToLocal.length > 0) {
                serverToLocal.forEach((id) => {
                    this.set([id], true);
                });
            }
        });
    }

    private setApi(list: ApiItemsFavoritesIdsUpdateInPost["list"]) {
        if (!list) {
            return;
        }
        this.storageFavorites.putApi(list).subscribe(() => {
            list.forEach((elem) => {
                if (elem.is_deleted === false) {
                    this.list.set(elem.id, true);
                }
            });

            this.storageFavorites.storage = this.mapToArray();
        });
    }

    private mapToArray(): StorageFavoritesItems[] {
        return Array.from(this.list, ([id, is]) => ({id, is}));
    }
}
