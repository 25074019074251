import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit} from "@angular/core";
import {filter, take} from "rxjs/operators";
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {Subscription} from "rxjs/internal/Subscription";
import {BasketService} from "@common/core/service/basket";
import {BehaviorSubject, Observable} from "rxjs";
import {Dialog, DialogRef} from "@angular/cdk/dialog";
import {DIALOG_UI_MENU_MOBILE_CONFIG} from "@shared/module_v2/dialog/ui/menu-mobile/menu-mobile";
import {AngularSvgIconModule} from "angular-svg-icon";
import {AsyncPipe, NgIf} from "@angular/common";
import {UserService} from "@common/core/service/user";
import {ActiveOrdersService} from "@core/service/active-orders/active-orders.service";
import {DialogOpenSearchDirective} from "@common/shared/component/dialog/ui/search/search.directive";

type Menu = "" | "menu" | "order" | "search" | "main" | "basket";

@Component({
    selector: "app-ui-footer-mobile",
    standalone: true,
    imports: [AngularSvgIconModule, RouterLink, AsyncPipe, NgIf, DialogOpenSearchDirective],
    templateUrl: "./footer-mobile.component.html",
    styleUrls: ["./footer-mobile.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterMobileComponent implements OnInit, OnDestroy {
    public countItem: Observable<number>;
    public activeMenu$ = new BehaviorSubject<Menu>("");

    private dialogMenuMobile: DialogRef;
    private router$: Subscription = new Subscription();
    private dialog = inject(Dialog);
    private router = inject(Router);
    private basket = inject(BasketService);
    private userService = inject(UserService);
    private activeOrdersService = inject(ActiveOrdersService);
    public countActiveOrders = this.activeOrdersService.count;
    private loadingDialog: boolean = false;

    constructor() {
        // Для создания активного меню
        this.router$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.checkActiveMenu();
        });
    }

    ngOnInit(): void {
        this.countItem = this.basket.count$;
    }

    ngOnDestroy() {
        this.router$.unsubscribe();
    }

    onOpenBeforeSearch() {
        this.activeMenu$.next("search");
        this.dialogMenuMobile?.close();
    }
    onCloseSearch() {
        this.checkActiveMenu();
    }

    onClickMenu() {
        if (!this.loadingDialog) {
            this.loadingDialog = true;
            if (this.activeMenu$.value !== "menu") {
                this.activeMenu$.next("menu");
                import("@shared/module_v2/dialog/ui/menu-mobile/menu-mobile.component")
                    .then((res) => {
                        setTimeout(() => {
                            this.dialogMenuMobile = this.dialog.open(res.UiMenuMobileComponent, DIALOG_UI_MENU_MOBILE_CONFIG);
                            this.dialogMenuMobile.closed.pipe(take(1)).subscribe(() => {
                                this.checkActiveMenu();
                            });
                        });
                    })
                    .finally(() => {
                        this.loadingDialog = false;
                    });
            } else {
                this.activeMenu$.next("");
                this.dialogMenuMobile?.close();
                this.loadingDialog = false;
            }
        }
    }

    checkActiveMenu() {
        this.activeMenu$.next("");
        const url = this.router.url;

        if (url.indexOf("/basket") !== -1) {
            this.activeMenu$.next("basket");
        } else if (url.indexOf("/search") !== -1) {
            this.activeMenu$.next("search");
        } else if (url === "/lk/orders" || url === "/check") {
            this.activeMenu$.next("order");
        } else if (url === "/") {
            this.activeMenu$.next("main");
        }
    }

    onClickOrder() {
        if (this.userService.isAuth) {
            this.router.navigateByUrl("/lk/orders");
        } else {
            this.router.navigateByUrl("/check");
        }
    }
}
