<div class="box__input">
    <input
        #phone
        type="tel"
        name="phone"
        inputmode="tel"
        autocomplete="tel"
        [(ngModel)]="value"
        mask="000) 000 00 00"
        [patterns]="customPatterns"
        showMaskTyped="true"
        prefix="+7 ("
        shownMaskExpression="___) ___ __ __"
        placeholder="+7 (___) ___ __ __"
        [inputTransformFn]="inputTransformFn"
        [outputTransformFn]="outputTransformFn"
        (focus)="eventFocus()"
        (blur)="eventBlur()"
        (click)="eventClick()"
        (keydown.enter)="fakeEnter($event)"
        (keyup.enter)="evenEnter($event)"
        (ngModelChange)="valueChange($event)"
        [class]="inputClass"
        [tabindex]="tabindex"
        [class.not-active]="isNotActiveInput()"
        [class.error]="control.invalid && submit()"
        [class.succ]="control.valid && submit()" />
    <app-svg-error *ngIf="control.invalid && submit() && showSvgError"></app-svg-error>
</div>
<div [@inputError] class="error__text" *ngIf="textError && control.invalid && submit()">{{ textError }}</div>
