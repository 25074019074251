import {ChangeDetectionStrategy, Component} from "@angular/core";
import {SvgIconComponent} from "angular-svg-icon";
import {RouterLink} from "@angular/router";

@Component({
    selector: "ma-menu-reviews",
    standalone: true,
    imports: [SvgIconComponent, RouterLink],
    templateUrl: "./reviews.component.html",
    styleUrl: "./reviews.component.less",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuReviewsComponent {}
