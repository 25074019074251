import {inject, Injectable} from "@angular/core";
import {ApiItemsFavoritesIdsUpdateInPost} from "./interface";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ApiItemsFavoritesIdsUpdateService {
    private httpClient = inject(HttpClient);

    post(_obj: ApiItemsFavoritesIdsUpdateInPost): Observable<void> {
        return this.httpClient.post<void>("ma/site/v1/items/favorites/ids/update", _obj, {withCredentials: true});
    }
}
