import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, retry, throwError, timer} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpError} from "@core/model/http-error";
import * as Sentry from "@sentry/angular";
import {environment} from "../../../src/environments/environment";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private maxRetries = 3;
    private delayMs = 3000;
    private statusError: number[] = [502, 504];

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retry({
                delay: (error) => {
                    if ([0, ...this.statusError].indexOf(error.status) !== -1) {
                        return timer(this.delayMs);
                    }

                    throw error;
                },
                count: this.maxRetries,
            }),
            catchError((error: HttpErrorResponse) => {
                Sentry.addBreadcrumb({
                    category: "HttpErrorResponse",
                    message: JSON.stringify(error),
                    level: "debug",
                });

                const reply: HttpError = {
                    message: "",
                    status: null,
                    action: "",
                    data: error,
                };
                if (error.error) {
                    reply.message = error.error.message || "";
                    reply.status = error.status >= 0 ? error.status : null;
                    reply.action = error.error.action || "";
                }
                if (error.status === 0) {
                    reply.message = "Ошибка сети";
                }

                if (this.statusError.indexOf(error.status) !== -1) {
                    const reg = new RegExp(environment.api.url, "ig");
                    const url = request.url.replace(reg, environment.api.urlReserve);
                    environment.api.url = environment.api.urlReserve;
                    return next.handle(request.clone({url: url}));
                }
                return throwError(() => reply);
            }),
        );
    }
}
