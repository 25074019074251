import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LayoutHeaderComponent} from "./header.component";
import {SvgLogoMaModule} from "@shared/module_v2/svg/logo-ma/logo-ma.module";
import {RouterModule} from "@angular/router";
import {ButtonCityLinkModule} from "@shared/module_v2/button/city-link/city-link.module";
import {SvgMedalModule} from "@shared/module_v2/svg/medal/medal.module";
import {LogoComponent} from "./logo/logo.component";
import {CityComponent} from "./city/city.component";
import {SharedPipeModule} from "@shared/shared.pipe";
import {SvgArrowDownModule} from "@shared/module_v2/svg/arrow-down/arrow-down.module";
import {SvgPhoneModule} from "@shared/module_v2/svg/phone/phone.module";
import {UiBasketSmallModule} from "@shared/module_v2/ui/basket-small/basket-small.module";
import {MenuComponent} from "./menu/menu.component";
import {MyOrderComponent} from "./my-order/my-order.component";
import {ProfileComponent} from "./profile/profile.component";
import {SearchComponent} from "./search/search.component";
import {InputSearchModule} from "@shared/module_v2/input/search/search.module";
import {MenuDropComponent} from "./menu-drop/menu-drop.component";
import {SharedDirectiveModule} from "@shared/shared.directive";
import {LayoutMenuCatalogModule} from "@shared/module_v2/layout/menu-catalog/menu-catalog.module";
import {CitySelectionDirective} from "@common/shared/component/dialog/ui/city-selection/city-selection.directive";
import {UiPopupYourCityComponent} from "@shared/module_v2/ui/popup-your-city/popup-your-city.component";
import {SearchHeaderComponent} from "@shared/module_v2/ui/search-header/search-header.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {FavoriteComponent} from "@component/layout/header/favorite/favorite.component";
import {MenuReviewsComponent} from "@component/layout/header/reviews/reviews.component";

@NgModule({
    declarations: [
        LayoutHeaderComponent,
        LogoComponent,
        CityComponent,
        MenuComponent,
        MyOrderComponent,
        ProfileComponent,
        SearchComponent,
        MenuDropComponent,
    ],
    imports: [
        CommonModule,
        SvgLogoMaModule,
        RouterModule,
        ButtonCityLinkModule,
        SvgMedalModule,
        SharedPipeModule,
        SvgArrowDownModule,
        SvgPhoneModule,
        UiBasketSmallModule,
        InputSearchModule,
        SharedDirectiveModule,
        LayoutMenuCatalogModule,
        UiPopupYourCityComponent,
        SearchHeaderComponent,
        AngularSvgIconModule,
        CitySelectionDirective,
        FavoriteComponent,
        MenuReviewsComponent,
    ],
    exports: [LayoutHeaderComponent],
})
export class LayoutHeaderModule {}
