import {Directive, EventEmitter, Output} from "@angular/core";
import {DIALOG_UI_CITY_SELECTION_CONFIG} from "@common/shared/component/dialog/ui/city-selection/city-selection";
import {DialogDirective} from "@common/abstract/dialog-directive";
import {dialogOpen} from "@common/helpers/dialog-open";
import {UiDialogSearchComponent} from "@common/shared/component/dialog/ui/search/search.component";

@Directive({
    selector: "[maDialogCitySelection]",
    standalone: true,
    host: {
        "(click)": "click()",
    },
})
export class CitySelectionDirective extends DialogDirective {
    @Output() onClose = new EventEmitter<void>();
    @Output() onOpenBefore = new EventEmitter<void>();
    @Output() onOpenAfter = new EventEmitter<void>();

    click() {
        this.onOpenAfter.emit();
        this.loadingCommonState.start();
        if (!this.loading) {
            this.loading = true;

            dialogOpen<UiDialogSearchComponent>(
                () => import("@common/shared/component/dialog/ui/city-selection/city-selection.component"),
                (component) => {
                    this.dialog.open(component, DIALOG_UI_CITY_SELECTION_CONFIG);
                },
            ).finally(() => {
                this.loading = false;
                this.loadingCommonState.stop();
                this.onOpenBefore.emit();
            });
        }
    }
}
