import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal, WritableSignal} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DialogOpenSearchDirective} from "@common/shared/component/dialog/ui/search/search.directive";

@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DialogOpenSearchDirective],
})
export class SearchComponent implements OnInit, OnDestroy {
    public query: WritableSignal<string> = signal<string>("");
    private queryParamMap$: Subscription = new Subscription();
    private onClose$: Subscription = new Subscription();
    private _activatedRoute = inject(ActivatedRoute);
    private dialogOpenSearchDirective = inject(DialogOpenSearchDirective);

    ngOnInit() {
        this.initSubs();
    }

    ngOnDestroy() {
        this.queryParamMap$.unsubscribe();
        this.onClose$.unsubscribe();
    }

    initSubs() {
        this.queryParamMap$ = this._activatedRoute.queryParamMap.subscribe((res) => {
            this.query.set(res.has("q") ? res.get("q") : "");
        });
        this.onClose$ = this.dialogOpenSearchDirective.onClose.subscribe((res) => {
            this.query.set(res);
        });
    }

    onFocusMobile() {
        this.dialogOpenSearchDirective.clear = this.query() === "";
        this.dialogOpenSearchDirective.click();
    }

    onClearValue() {
        this.dialogOpenSearchDirective.clear = true;
        this.dialogOpenSearchDirective.click();
        this.query.set("");
    }
}
