import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {InputPhoneComponent} from "@shared/module_v2/input/phone/phone.component";
import {FormsModule} from "@angular/forms";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import {SvgErrorModule} from "@component/svg/error/svg-error.module";

@NgModule({
    declarations: [InputPhoneComponent],
    imports: [CommonModule, FormsModule, NgxMaskDirective, SvgErrorModule],
    exports: [InputPhoneComponent],
    providers: [provideNgxMask()],
})
export class InputPhoneModule {}
