import {inject, Injectable} from "@angular/core";
import {MetaTagService} from "@core/service/meta-tag";
import {PlatformService} from "@common/core/service/platform";
import {MailAnalyticsService} from "@core/service/mail-analytics";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {TelemetryService} from "@core/service/telemetry";
import {UserService} from "@common/core/service/user/";
import {TalkMeService} from "@shared/service/talk-me/talk-me";
import {UtmService} from "@core/service/utm/utm.service";
import {BasketService} from "@common/core/service/basket";

@Injectable({providedIn: "root"})
export class AutorunService {
    private basketService = inject(BasketService);
    constructor(
        private _metaTagService: MetaTagService,
        private _platform: PlatformService,
        private _mailService: MailAnalyticsService,
        private _yandexMetrikaService: YandexMetrikaService,
        private _userService: UserService,
        private _talkMeService: TalkMeService,
        private _utmService: UtmService,
        private _telemetryService: TelemetryService,
    ) {}

    /**
     * Запус из главного ngOnInit AppComponent (src/app/app.list.menu-aside.component.ts)
     */
    public onInit() {
        // ПОРЯДОК НЕ МЕНЯТЬ

        // Отслеживания title
        this._metaTagService.run();

        this._utmService.init();

        if (this._platform.browser) {
            // Проверка авторизации
            this._userService.run();
            this.basketService.init();

            if (document.readyState === "loading") {
                document.addEventListener("load", () => {
                    this.loadingScript();
                });
            } else {
                this.loadingScript();
            }
        }

        // Наша метрика
        this._telemetryService.identification();
    }

    private loadingScript() {
        setTimeout(() => {
            // Яндекс метрика
            this._yandexMetrikaService.start();

            // Майл сервис
            this._mailService.start();

            this._talkMeService.start();
        }, 4000);
    }
}
