import {environment} from "./environments/environment";
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {CONFIG_BROWSER} from "./app/app.config.browser";
import * as Sentry from "@sentry/angular";

Sentry.init({
    environment: environment.production ? "production" : "dev",
    release: environment.sentry.release,
    dsn: environment.sentry.dsn,
    sendDefaultPii: true,
    autoSessionTracking: true,
    maxBreadcrumbs: 200,
    initialScope: {
        tags: {rendering: "csr"},
    },
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: environment.sentry.tracingOrigins,
    ignoreErrors: environment.sentry.ignoreErrors,
    denyUrls: environment.sentry.denyUrls,
    tracesSampleRate: environment.sentry.tracesSampleRate,
    beforeSend(event) {
        const url = window.location.pathname;
        if (!url.includes("/basket") && +Math.random().toFixed(2) >= environment.sentry.sampleRate) {
            return null;
        }
        return event;
    },
    beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.data && breadcrumb.data?.url) {
            if (
                breadcrumb.data.url.includes("yandex.ru") ||
                breadcrumb.data.url.includes("me-talk.ru") ||
                breadcrumb.data.url.includes("mail.ru")
            ) {
                return null;
            }
        }
        return breadcrumb;
    },
});

bootstrapApplication(AppComponent, {providers: CONFIG_BROWSER}).catch((err) => {
    // eslint-disable-next-line no-console
    console.log("catch bootstrapModule");
    // eslint-disable-next-line no-console
    console.log(err);
});
