import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {SvgIconComponent} from "angular-svg-icon";
import {Router} from "@angular/router";
import {FavoritesService} from "@common/shared/service/favorites/favorites.service";

@Component({
    selector: "ma-favorite",
    standalone: true,
    imports: [SvgIconComponent],
    templateUrl: "./favorite.component.html",
    styleUrl: "./favorite.component.less",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteComponent {
    private router = inject(Router);
    public link = inject(FavoritesService).linkPage;

    onClick() {
        this.router.navigateByUrl(this.link());
    }
}
