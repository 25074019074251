<div class="menu-catalog">
    <ma-layout-menu-catalog></ma-layout-menu-catalog>
</div>

<a routerLink="/specials" [routerLinkActive]="['active']" class="menu-elem icon">
    <svg-icon class="icon__megasovety" src="icons/svg/megasovety.svg"></svg-icon>
    Мегасоветы
</a>
<a routerLink="/megapharmacist" [routerLinkActive]="['active']" class="menu-elem icon">
    <svg-icon class="icon__megasovety" src="icons/svg/pharmacist.svg"></svg-icon>
    Мегапровизор
</a>
<a routerLink="/pharmacies" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" class="menu-elem icon">
    <svg-icon class="icon__megasovety" src="icons/svg/first-aid-kit.svg"></svg-icon>
    Для аптек
</a>
<a routerLink="/help" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" class="menu-elem how-order">
    Как заказать
</a>
<div class="menu-elem" [class.active]="about.active" (mouseenter)="open(about)" (mouseleave)="close(about)">
    О компании
    <app-svg-arrow-down class="icon__arrow-down"></app-svg-arrow-down>
    <app-menu-drop [list]="aboutCompanyList" *ngIf="about.active" (click)="about.active = !about.active"></app-menu-drop>
</div>
<div class="menu-elem" [class.active]="help.active" (mouseenter)="open(help)" (mouseleave)="close(help)">
    Помощь
    <app-svg-arrow-down class="icon__arrow-down"></app-svg-arrow-down>
    <app-menu-drop [list]="helpList" *ngIf="help.active" (click)="help.active = !help.active"></app-menu-drop>
</div>
<a routerLink="/reviews" [routerLinkActive]="['active']" class="menu-elem reviews"> Отзывы </a>
