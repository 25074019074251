import {
    AfterViewInit,
    Attribute,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    ViewChild,
} from "@angular/core";
import {AbstractControl, FormControl} from "@angular/forms";
import {of} from "rxjs/internal/observable/of";
import {Subscription} from "rxjs/internal/Subscription";
import {InputErrorAnimation} from "@shared/animation/input-error.animations";

@Component({
    selector: ".c-input-phone, ma-input-phone",
    templateUrl: "phone.component.html",
    styleUrls: ["./phone.component.less", "../../../../../less/partials/tags/input.less"],
    animations: [InputErrorAnimation],
})
export class InputPhoneComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() control: FormControl;
    @Input() inputClass: string = "";
    @Input() focus: boolean = false;
    @Input() tabindex: string = "";
    @Input() textError: string = "";
    @Input() isEmpty: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild("phone", {static: true}) elemInput: ElementRef;

    private submit$: Subscription;
    private valueChanges$: Subscription;
    public submit = signal<boolean>(false);
    public isNotActiveInput = signal<boolean>(true);
    public value = "";
    public showSvgError: boolean = true;

    public customPatterns = {
        "0": {
            pattern: new RegExp("[0-9]"),
        },
    };
    public inputTransformFn = (value: unknown): string => {
        if (!value) return "";

        const strValue = String(value);
        // For paste (only if full-phone)
        // mask length + full-phone length = 29
        const isPaste = strValue.startsWith("+7 (") && strValue.length > 28;
        // For paste (only if full-phone)
        // full-phone length = 11
        const isAutoFill =
            !strValue.startsWith("+7 (") &&
            (strValue.startsWith("+7") || strValue.startsWith("+8") || strValue.startsWith("7") || strValue.startsWith("8")) &&
            strValue.length > 10;

        if (!isPaste && !isAutoFill) return strValue;

        // Remove all non-digit characters
        let digits = strValue.replace(/\D+/g, "");

        if (isPaste) {
            // Remove first '7' out of '+7' from mask
            digits = digits.substring(1);
        }

        // Remove first '7' or '8' from input phone
        return digits.replace(/^[87]/, "");
    };
    public outputTransformFn = (value: string | number | null | undefined): string => {
        if (!value) return "";
        return `7${String(value)}`;
    };

    constructor(@Attribute("showSvgError") public _showSvgError: string | null) {
        this.showSvgError = _showSvgError !== "false";
    }

    ngOnInit() {
        if (this.control.parent.get("submit") instanceof FormControl) {
            this.submit.set(this.control.parent.get("submit").value);
            this.submit$ = this.control.parent.get("submit").valueChanges.subscribe((res) => {
                this.submit.set(res);
            });
        }

        if (this.control.value) {
            const cleanedValue = this.control.value.replace(/\D+/g, "");
            const isFullPhone = /^[78]\d{10}$/.test(cleanedValue);
            this.value = isFullPhone ? cleanedValue.slice(1) : cleanedValue;
            if (this.control.valid) {
                this.isNotActiveInput.set(false);
            }
        }

        this.valueChanges$ = this.control.valueChanges.subscribe((res) => {
            this.value = res?.startsWith("7") ? res.substring(1) : res;
            if (this.control.valid) {
                this.isNotActiveInput.set(false);
            }
        });

        this.control.setAsyncValidators((control: AbstractControl) => {
            let value: string = control.value;
            if (value) {
                value = value.replace(/[^0-9]/gi, "");
                if (!new RegExp(/[0-9]{11}/i).test(value)) {
                    return of({phone: true});
                } else if (value.length === 11) {
                    return of(null);
                } else {
                    return of(null);
                }
            }

            return this.isEmpty ? of(null) : of({phone: true});
        });
    }

    ngOnDestroy() {
        if (this.submit$) {
            this.submit$.unsubscribe();
        }
        if (this.valueChanges$) {
            this.valueChanges$.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.focus) {
            if (this.elemInput) {
                this.elemInput.nativeElement.focus();
                this.elemInput.nativeElement.click();
            }
        }
    }

    /**
     * Обработка кнопки "Enter"
     */
    evenEnter(event: KeyboardEvent): void {
        event.preventDefault();
        this.enter.emit(true);
    }
    /**
     * Чтобы не триггерить form
     */
    fakeEnter(event: KeyboardEvent) {
        event.preventDefault();
    }

    eventFocus() {
        this.isNotActiveInput.set(false);
    }

    eventClick() {
        this.isNotActiveInput.set(false);
    }

    eventBlur() {
        this.isNotActiveInput.set(!this.control.value);
    }

    valueChange(str: string) {
        this.control.setValue(str);
    }
}
