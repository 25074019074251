<div class="footer-mobile__container">
    <div class="footer-mobile__block" [class.active]="(activeMenu$ | async) === 'main'" routerLink="/">
        <div class="footer-mobile__icon">
            <svg-icon class="icon" [src]="'icons/svg/home.svg'"></svg-icon>
        </div>
        <div class="footer-mobile__text">Главная</div>
    </div>
    <div
        maDialogOpenSearch
        (onOpenBefore)="onOpenBeforeSearch()"
        (onClose)="onCloseSearch()"
        class="footer-mobile__block"
        [class.active]="(activeMenu$ | async) === 'search'">
        <div class="footer-mobile__icon">
            <svg-icon class="icon" [src]="'icons/svg/search-3.svg'"></svg-icon>
        </div>
        <div class="footer-mobile__text">Поиск</div>
    </div>
    <div class="footer-mobile__block" routerLink="/basket" [class.active]="(activeMenu$ | async) === 'basket'">
        <div class="footer-mobile__icon">
            <div class="footer-mobile__icon-count" *ngIf="countItem | async">{{ countItem | async }}</div>
            <svg-icon class="icon" [src]="'icons/svg/basket.svg'"></svg-icon>
        </div>
        <div class="footer-mobile__text footer-mobile_text_red">Корзина</div>
    </div>
    <div class="footer-mobile__block" (click)="onClickOrder()" [class.active]="(activeMenu$ | async) === 'order'">
        <div class="footer-mobile__icon">
            <div class="footer-mobile__icon-count" *ngIf="countActiveOrders() > 0">{{ countActiveOrders() }}</div>
            <svg-icon class="icon" [src]="'icons/svg/box-1.svg'"></svg-icon>
        </div>
        <div class="footer-mobile__text">Заказы</div>
    </div>
    <div (click)="onClickMenu()" class="footer-mobile__block" [class.active]="(activeMenu$ | async) === 'menu'">
        <div class="footer-mobile__icon icon_menu">
            <div class="footer-mobile__menu">
                <span></span>
            </div>
        </div>
        <div class="footer-mobile__text">Меню</div>
    </div>
</div>
