import {ChangeDetectionStrategy, Component, HostListener, inject} from "@angular/core";
import {UserService} from "@common/core/service/user";
import {Router} from "@angular/router";

@Component({
    selector: "ma-my-order",
    templateUrl: "./my-order.component.html",
    styleUrls: ["./my-order.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyOrderComponent {
    private _userService = inject(UserService);
    private _router = inject(Router);

    @HostListener("click") onClick() {
        if (this._userService.isAuth) {
            this._router.navigateByUrl("/lk/orders");
        } else {
            this._router.navigateByUrl("/check");
        }
    }
}
