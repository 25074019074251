import {Directive, inject, Input, OnDestroy, OnInit} from "@angular/core";
import {PRIMARY_OUTLET, Router, RouterLink, UrlSegment, UrlSegmentGroup} from "@angular/router";
import {CityService} from "@core/service/city";
import {Subscription} from "rxjs";

@Directive({
    selector: "[maLinkCity]",
    standalone: true,
})
export class LinkCityDirective implements OnInit, OnDestroy {
    private router = inject(Router);
    private link = inject(RouterLink);
    private city = inject(CityService);
    private city$ = new Subscription();
    @Input() routerLink: string | string[] = "";

    ngOnInit() {
        // Пока что в разработке
        // this.initSubs();
        this.parseHref();
    }

    ngOnDestroy() {
        this.city$.unsubscribe();
    }

    private initSubs() {
        this.city$ = this.city.city$.subscribe(() => {
            this.parseHref();
        });
    }

    private parseHref(): string {
        if (!this.routerLink) {
            return;
        }
        if (this.routerLink instanceof Array) {
            this.routerLink = this.routerLink.join("/");
        }
        const url = this.router.parseUrl(this.routerLink.replace(/\/{2}/, "/"));
        const groupSegment: UrlSegmentGroup = url.root.children[PRIMARY_OUTLET];
        groupSegment.segments.unshift(new UrlSegment(this.city.data.code, {}));

        this.link.routerLink = `/${groupSegment.toString()}`;
        this.link.ngOnChanges({
            routerLink: {
                currentValue: "/",
                previousValue: null,
                firstChange: false,
                isFirstChange: () => false,
            },
        });
    }
}
