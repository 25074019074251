import {inject, Injectable, makeStateKey, TransferState} from "@angular/core";
import {HttpHandler, HttpRequest, HttpResponse} from "@angular/common/http";

import {PlatformService} from "@common/core/service/platform";
import {of, tap} from "rxjs";
import {API_URLS_YOUR_CACHING} from "@common/constants/api-urls-your-caching";

@Injectable()
export class HttpCachingInterceptor {
    private transferState = inject(TransferState);
    private platform = inject(PlatformService);

    /**
     * Для того чтобы города делали меньше запросов
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Если запрос к статическим файлам или внешним ресурсам, то пропускаем
        if (/^(\.*\/*assets|https:|http:)/i.test(req.url)) {
            return next.handle(req);
        }
        if (req.method !== "GET") {
            return next.handle(req);
        }

        if (!req.url.includes(API_URLS_YOUR_CACHING.CITIES_LOCATE)) {
            return next.handle(req);
        }
        const json = <string>JSON.stringify(req.params.get("data"));
        const cacheKey: any = makeStateKey(req.url + json);

        if (this.transferState.hasKey(cacheKey)) {
            return of(new HttpResponse<any>({body: this.transferState.get(cacheKey, null)})).pipe(
                tap(() => {
                    if (this.platform.browser) {
                        this.transferState.remove(cacheKey);
                    }
                }),
            );
        } else {
            if (this.platform.server) {
                return next.handle(req).pipe(
                    tap((stateEvent) => {
                        if (stateEvent instanceof HttpResponse) {
                            // нужно чтобы когда нет города получать из State в браузере полученый уже запрос от server
                            if (req.url.includes(API_URLS_YOUR_CACHING.CITIES_LOCATE)) {
                                const result = stateEvent.body;
                                if (!!Object.keys(req.params.get("data")).length && result["city"]["id"]) {
                                    this.transferState.set(
                                        makeStateKey(req.url + JSON.stringify(JSON.stringify({id: result["city"]["id"]}))),
                                        stateEvent.body,
                                    );
                                    this.transferState.set(
                                        makeStateKey(req.url + JSON.stringify(JSON.stringify({code: result["city"]["code"]}))),
                                        stateEvent.body,
                                    );
                                }
                            }
                        }
                    }),
                );
            }
        }
        return next.handle(req);
    }
}
