import {ErrorHandler, inject, Injectable} from "@angular/core";
import {PlatformService} from "../service/platform";
import {throwError} from "rxjs";
import * as Sentry from "@sentry/angular";
import {HttpError} from "@core/model/http-error";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    private platformService = inject(PlatformService);

    handleError(error: Error & HttpError) {
        if (error.message) {
            if (this.platformService.browser) {
                if (error.message.indexOf("Loading chunk") !== -1) {
                    window.location.reload();
                    return;
                }
            }
        }
        let ssr = "";
        if (this.platformService.server) {
            ssr = "SSR ";
        }
        if (this.platformService.browser) {
            if (error.status === 0) {
                Sentry.captureMessage(`${ssr}🛜Ошибка подключение, статус 0`, (scope) => {
                    scope.setTransactionName(`Нет доступа к интернету`);
                    return scope;
                });
            } else if (error.status > 400) {
                const url = error.data.url.replace(/\?.*/, "");
                const nameError = new Error(url);
                const nameTransaction = `${error.data.name} ${error.status}`;
                nameError.name = `${ssr}ERROR API`;

                Sentry.captureException(nameError, (scope) => {
                    scope.addBreadcrumb({
                        message: JSON.stringify(error),
                        level: "log",
                    });
                    scope.setTransactionName(nameTransaction);
                    scope.setFingerprint(["custom-error", url, nameTransaction, nameError.name]);
                    return scope;
                });
            } else {
                error.name = `${ssr}${error.name}`;
                Sentry.captureException(error, (scope) => {
                    scope.setFingerprint(["custom-error", error.message, error.name]);
                    return scope;
                });
            }
        }

        console.error(error);
        return throwError(() => error);
    }
}
