import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AbTestCookieService} from "./ab-test-cookie.service";
import {AbTestOptions, AbTestsModule} from "@core/module/angular-ab-tests/module";
import {AbTestsServerModule} from "@core/module/angular-ab-tests/server/ab-tests-server.module";
import {CookieHandler} from "@core/module/angular-ab-tests/classes";
import {AbTestsService} from "@core/module/angular-ab-tests/service";

export const abTestsOptions: AbTestOptions[] = [
    {
        versions: ["v3", "v4", "v5"],
        scope: "banner_57854_v2",
        expiration: 180,
    },
    {
        versions: ["v1", "v2", "v3"],
        scope: "banner_58665",
        expiration: 180,
    },
];

@NgModule({
    declarations: [],
    imports: [CommonModule, AbTestsModule.forRoot(abTestsOptions), AbTestsServerModule],
    exports: [AbTestsModule, AbTestsServerModule],
    providers: [
        {
            provide: CookieHandler,
            useClass: AbTestCookieService,
        },
        AbTestsService,
    ],
})
export class AbTestModule {}
