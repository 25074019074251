<div class="header">
    <app-logo class="logo" />
    <app-city class="city" />
    <ma-my-order class="my-order" />
    <ma-favorite class="favorite" />
    <app-profile class="profile" />
</div>
<div class="center">
    <app-search></app-search>
    <ma-ui-basket-small [inFixed]="false" class="basket-small"></ma-ui-basket-small>
</div>

<div class="footer">
    <nav>
        <app-menu class="menu"></app-menu>
        <ma-menu-reviews class="menu-reviews"></ma-menu-reviews>
    </nav>
</div>
