import {inject, Injectable} from "@angular/core";
import {LocalStorageService} from "@common/core/service/localstorage";
import {map} from "rxjs/operators";
import {ApiItemsFavoritesIdsService} from "@common/shared/service/api/items/favorites/ids";
import {ApiItemsFavoritesIdsUpdateInPost, ApiItemsFavoritesIdsUpdateService} from "@common/shared/service/api/items/favorites/ids/update";
import {Observable} from "rxjs";

export interface StorageFavoritesItems {
    id: number;
    is: boolean;
}

@Injectable({
    providedIn: "root",
})
export class StorageFavorites {
    private readonly NAME_STORAGE = "list";
    private readonly NAME_STORAGE_NEW = "ma_favorites";
    private localStorageService = inject(LocalStorageService);
    private apiItemsFavoritesIdsService = inject(ApiItemsFavoritesIdsService);
    private apiItemsFavoritesIdsUpdateService = inject(ApiItemsFavoritesIdsUpdateService);

    /**
     * Запись значение в хранилище
     *
     * @param {StorageFavoritesItems[]} value
     */
    public set storage(value: StorageFavoritesItems[]) {
        this.localStorageService.set(this.NAME_STORAGE_NEW, JSON.stringify(value));
    }

    /**
     * Возвращает значение из хранилища
     *
     * @returns {StorageFavoritesItems[]}
     */
    public get storage(): StorageFavoritesItems[] {
        try {
            const stNew: StorageFavoritesItems[] = JSON.parse(this.localStorageService.get(this.NAME_STORAGE_NEW)) || [];

            if (stNew.length > 0) {
                return stNew;
            } else {
                const stOld: number[] = JSON.parse(this.localStorageService.get(this.NAME_STORAGE));
                if (stOld) {
                    this.storage = stOld.map((value) => {
                        return {
                            id: value,
                            is: false,
                        };
                    });
                    return this.storage;
                }
            }

            return [];
        } catch {
            return [];
        }
    }

    public putApi(list: ApiItemsFavoritesIdsUpdateInPost["list"]): Observable<void> {
        return this.apiItemsFavoritesIdsUpdateService.post({
            list: list,
        });
    }

    public getItemsIdApi() {
        return this.apiItemsFavoritesIdsService.get().pipe(
            map((res) => {
                res.ids = res.ids?.map(Number) || [];
                return res.ids;
            }),
        );
    }
}
