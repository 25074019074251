import {ComponentType} from "@angular/cdk/overlay";
import {requestIdleCallback} from "@common/helpers/requestIdleCallback";

interface ImportFn {
    (): Promise<any>;
}

interface OpenFn<T> {
    (component: ComponentType<T>): void;
}

/**
 * Открытие диалога с компонентом
 *
 * @param importFn
 * @param openFn
 */
export async function dialogOpen<T>(importFn: ImportFn, openFn: OpenFn<T>): Promise<ComponentType<T>> {
    const componentModule = await importFn();
    return new Promise((resolve) => {
        requestIdleCallback(() => {
            const component = Object.values(componentModule)[0] as ComponentType<T>;
            if (component === undefined) {
                console.error("Component is undefined");
                return;
            }
            openFn(component);
            resolve(component);
        });
    });
}
