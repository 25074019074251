import {EventEmitter, inject} from "@angular/core";
import {LoadingCommonState} from "@state/loading-common.state";
import {Dialog} from "@angular/cdk/dialog";

type EventEmitterType = void | number | boolean | string | object;

/**
 * Abstract class for dialog directive
 */
export abstract class DialogDirective {
    protected loadingCommonState = inject(LoadingCommonState);
    protected dialog = inject(Dialog);
    protected loading = false;

    protected abstract onClose: EventEmitter<EventEmitterType>;
    protected abstract onOpenBefore: EventEmitter<EventEmitterType>;
    protected abstract onOpenAfter: EventEmitter<EventEmitterType>;

    protected abstract click(): void;
}
