import {Directive, EventEmitter, Input, Output} from "@angular/core";
import {dialogOpen} from "@common/helpers/dialog-open";
import {UiDialogSearchComponent} from "@common/shared/component/dialog/ui/search/search.component";
import {DIALOG_UI_SEARCH_CONFIG} from "@common/shared/component/dialog/ui/search/search";
import {DialogDirective} from "@common/abstract/dialog-directive";

@Directive({
    selector: "[maDialogOpenSearch]",
    standalone: true,
    host: {
        "(click)": "click()",
    },
})
export class DialogOpenSearchDirective extends DialogDirective {
    @Output() onClose = new EventEmitter<string>();
    @Output() onOpenBefore = new EventEmitter<void>();
    @Output() onOpenAfter = new EventEmitter<void>();
    @Input() clear: boolean = false;

    click() {
        this.onOpenAfter.emit();
        this.loadingCommonState.start();
        if (!this.loading) {
            this.loading = true;

            dialogOpen<UiDialogSearchComponent>(
                () => import("@common/shared/component/dialog/ui/search/search.component"),
                (component) => {
                    const options = DIALOG_UI_SEARCH_CONFIG;
                    options.data.clear = this.clear;
                    const dialogRef = this.dialog.open(component, options);
                    dialogRef.closed.subscribe((res: string) => {
                        this.onClose.emit(res);
                    });
                },
            ).finally(() => {
                this.loading = false;
                this.loadingCommonState.stop();
                this.onOpenBefore.emit();
            });
        }
    }
}
