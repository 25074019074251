import {inject, Injectable, Injector, isDevMode} from "@angular/core";
import {HttpHandler, HttpHeaders, HttpInterceptor as HttpInterceptorCore, HttpParams, HttpRequest} from "@angular/common/http";
import {ConfigService} from "@common/core/service/config";
import {LoggerService} from "@common/core/service/logger";
import {PlatformService} from "@common/core/service/platform";
import {REQUEST} from "@common/tokens/express.tokens";
import HmacSHA256 from "crypto-js/hmac-sha256";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {Cookie} from "@core/service/cookie";
import {ORIGIN_USER_AGENT} from "@shared/injection-token/tokens";

interface Data {
    [key: string]: string | number | boolean | Blob | File | Data | null | undefined;
}

interface searchFileResult {
    file: {
        path: string[];
        file: File | Blob;
    }[];
    data: Data;
}

@Injectable()
export class HttpInterceptor implements HttpInterceptorCore {
    private _logger: LoggerService;
    private _config: ConfigService;

    private _platformService: PlatformService;
    private _request: any;
    private _cookieService: Cookie;
    private _deviceTokenService: DeviceTokenService;

    private data: any;
    private userAgent = inject(ORIGIN_USER_AGENT);

    constructor(private _injector: Injector) {
        this._logger = _injector.get(LoggerService);
        this._config = _injector.get(ConfigService);
        this._platformService = _injector.get(PlatformService);
        this._request = _injector.get(REQUEST);
        this._cookieService = _injector.get(Cookie);
        this._deviceTokenService = _injector.get(DeviceTokenService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Если запрос к статическим файлам или внешним ресурсам, то пропускаем
        if (/^(\.*\/*assets|https:|http:)/i.test(req.url)) {
            return next.handle(req);
        }

        if (req.method === "GET") {
            this.data = this.params(req.params);

            const url = this.createUrl(req.url);
            const setParams = {
                "data": this.data,
                "ngsw-bypass": "true",
            };
            if (isDevMode()) {
                setParams["imnb"] = "1";
            }

            const secureReq = req.clone({
                url: url,
                setParams: setParams,
                headers: this.updateHeader(req.headers),
            });

            return next.handle(secureReq);
        }

        if (req.method === "PUT" || req.method === "POST") {
            this.data = this.params(req.body);
            let cloneReq = req.clone({
                url: this.createUrl(req.url),
                headers: this.updateHeader(req.headers),
            });

            const findFile = this.searchFile(req.body as Data, null, [], {});
            if (findFile.file.length > 0) {
                const formData = new FormData();
                findFile.file.forEach((v) => {
                    formData.set(v.path.join("."), v.file || "");
                });
                this.data = this.params(findFile.data);
                formData.set("data", this.data);
                cloneReq = cloneReq.clone({
                    body: formData,
                });
            }
            return next.handle(cloneReq);
        }

        return next.handle(req);
    }

    /**
     * Поиск файлов в запросе
     *
     * @param obj
     * @param result
     * @param previous
     * @param data
     */
    private searchFile(obj: Data, result: searchFileResult | null, previous: string[], data: Data): searchFileResult {
        if (result === null) {
            result = {
                file: [],
                data: {},
            };
        }
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (obj[key] instanceof Blob || obj[key] instanceof File) {
                    let file: File | Blob | undefined;
                    if (obj[key] instanceof File) {
                        file = obj[key] as File;
                    }
                    if (obj[key] instanceof Blob) {
                        file = obj[key] as Blob;
                    }
                    if (file !== undefined) {
                        result.file.push({
                            path: [...previous, key],
                            file: file,
                        });
                    }
                } else if (obj[key] !== null && typeof obj[key] === "object") {
                    if (!data[key]) {
                        data[key] = {};
                    }
                    this.searchFile(obj[key] as Data, result, [...previous, key], data[key] as Data);
                } else {
                    data[key] = obj[key];
                }
            }
        }
        result.data = data;
        return result;
    }

    /**
     * Подготовка данных
     *
     * @param params обьект с данными
     *
     * @returns {string}
     */
    private params(params: HttpParams | Data): string {
        if (params instanceof HttpParams) {
            const keys = params.keys();
            const obj: Record<string, string | null> = {};

            if (params.has("data")) {
                return params.get("data") as string;
            } else {
                keys.forEach((key: string) => {
                    if (params.has(key)) {
                        obj[key] = params.get(key);
                    }
                });
                return <string>JSON.stringify(obj);
            }
        }
        return <string>JSON.stringify(params);
    }

    /**
     * Именяем header
     *
     * @param httpHeaders
     */
    private updateHeader(httpHeaders: HttpHeaders): HttpHeaders {
        const configHeader = this._config.get("api")["header"];
        /**
         * Конфиг
         */
        Object.keys(configHeader).forEach((key) => {
            httpHeaders = httpHeaders.append(key.toString(), configHeader[key].toString());
        });

        /**
         * Токен
         */
        httpHeaders = httpHeaders.append("token", this.createToken()).append("Device-Token", this._deviceTokenService.get());

        const authToken = this._cookieService.getItem("auth_token") ? this._cookieService.getItem("auth_token") : "";
        if (authToken.length > 0) {
            httpHeaders = httpHeaders.append("auth_token", authToken).append("Auth-Token", authToken);
        }

        if (this._platformService.server) {
            httpHeaders = httpHeaders
                .append("x-forwarded-for", this._request["header"]["x-forwarded-for"])
                .append("User-Agent", this.userAgent);

            if (this._request["headers"]["ma-action-id"]) {
                httpHeaders = httpHeaders.append("ma-action-id", this._request["headers"]["ma-action-id"]);
            }
            if (this._request["headers"]["x-qrator-ip-source"]) {
                httpHeaders = httpHeaders.append("x-qrator-ip-source", this._request["headers"]["x-qrator-ip-source"]);
            }
        }

        return httpHeaders;
    }

    /**
     * Создания токена
     *
     * @private
     */
    private createToken(): string {
        const api = this._config.get("api");
        const secret = <string>api["secret"];

        return <string>HmacSHA256(this.data, secret).toString().toUpperCase();
    }

    /**
     * Создания url
     *
     * @param path
     * @returns {string}
     */
    private createUrl(path: string): string {
        let urlPath = "";
        const api = this._config.get("api");
        const port = api["port"];
        const protocol = api["protocol"];
        const host = api["url"];
        const queryParams = api["queryParams"] || null;

        urlPath += protocol + "://";
        urlPath += host;
        if (port) {
            urlPath += ":" + port;
        }
        urlPath += `/${path}`;

        const url = new URL(urlPath);

        if (Array.isArray(queryParams)) {
            queryParams.forEach((item) => {
                url.searchParams.set(item.key, item.value);
            });
        }

        return url.toString();
    }
}
