import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from "@angular/core";
import {CityService} from "@core/service/city";
import {City} from "@core/model/city";
import {PopupYourCityService} from "@common/shared/service/popup-your-city/popup-your-city.service";
import {PlatformService} from "@common/core/service/platform";
import {CitySelectionDirective} from "@common/shared/component/dialog/ui/city-selection/city-selection.directive";

@Component({
    standalone: true,
    imports: [CitySelectionDirective],
    selector: "ma-ui-popup-your-city",
    templateUrl: "./popup-your-city.component.html",
    styleUrls: ["./popup-your-city.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPopupYourCityComponent implements OnInit {
    public city: City;
    public isActive = signal(false);

    private popupYourCityService = inject(PopupYourCityService);
    private platform = inject(PlatformService);
    private _city = inject(CityService);

    ngOnInit() {
        this.city = this._city.city$.getValue();
        if (this.platform.browser) {
            if (!this.popupYourCityService.getStatus()) {
                this.isActive.set(true);
                this.popupYourCityService.setStatus();
            }
        }
    }

    close() {
        this.isActive.set(false);
        this.popupYourCityService.setStatus();
    }
}
