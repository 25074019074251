import {ChangeDetectionStrategy, Component, HostListener, inject, OnDestroy, OnInit, signal} from "@angular/core";
import {UserService} from "@common/core/service/user";
import {List} from "@shared/module_v2/layout/header/menu-drop/menu-drop.component";
import {BackUrlService} from "@common/core/service/back-url";
import {Subscription} from "rxjs";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {ngSkipHydration: "true"},
})
export class ProfileComponent implements OnInit, OnDestroy {
    public isMenu: boolean = false;
    public menuList: List[] = [
        {
            title: "Мой профиль",
            url: "/lk/profile",
        },
        {
            title: "Мои заказы",
            url: "/lk/orders",
        },
        {
            title: "Избранное",
            url: "/lk/favorites",
        },
        {
            title: "Мои адреса",
            url: "/lk/addresses",
        },
        {
            title: "Выйти",
            onClick: () => {
                this.userService.logout().subscribe(() => {
                    this.backUrlService.redirect();
                });
            },
        },
    ];
    private userService = inject(UserService);
    private backUrlService = inject(BackUrlService);

    private resultCheck$ = new Subscription();

    public isAuth = signal(false);

    @HostListener("mouseenter") onMouseEnter() {
        this.isMenu = true;
    }

    @HostListener("mouseleave") onMouseLeave() {
        this.isMenu = false;
    }

    ngOnInit(): void {
        this.resultCheck$ = this.userService.resultCheck.subscribe((res) => {
            this.isAuth.set(res);
        });
    }

    ngOnDestroy() {
        this.resultCheck$.unsubscribe();
    }
}
