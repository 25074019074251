import {Component, inject} from "@angular/core";
import {LoadingCommonState} from "@state/loading-common.state";

@Component({
    selector: "ma-loading-common",
    standalone: true,
    imports: [],
    template: "",
    styleUrls: ["./common.component.less"],
    host: {
        "[class.show]": "state()",
    },
})
export class LoadingCommonComponent {
    public state = inject(LoadingCommonState).status();
}
