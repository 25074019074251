import {ApplicationRef, inject, Injectable} from "@angular/core";
import {PreloadingStrategy, Route} from "@angular/router";
import {filter, Observable, of} from "rxjs";
import {take} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class CustomPreloadingStrategyService implements PreloadingStrategy {
    private appRef = inject(ApplicationRef);

    // Список компонентов для предзагрузки
    private preloadedComponents = [
        () => import("@common/shared/component/dialog/ui/search/search.component").then((m) => m.UiDialogSearchComponent),
        () =>
            import("@common/shared/component/dialog/ui/city-selection/city-selection.component").then(
                (m) => m.DialogCitySelectionComponent,
            ),
        () => import("@shared/module_v2/dialog/ui/menu-mobile/menu-mobile.component").then((m) => m.UiMenuMobileComponent),
    ];

    preload(route: Route, load: () => Observable<any>): Observable<any> {
        // Если у маршрута явно указано data.preload = true, загружаем его
        if (route.data && route.data["preload"]) {
            return load();
        }
        return of(null);
    }

    // Метод для загрузки компонентов
    preloadComponents() {
        this.appRef.isStable
            .pipe(
                filter((stable) => stable),
                take(1),
            )
            .subscribe(() => {
                this.preloadedComponents.forEach((loadComponent) => loadComponent());
            });
    }
}
