import {ChangeDetectionStrategy, Component, inject, signal} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {FooterSubscribeService} from "./sms-installation.service";
import {InputPhoneModule} from "@component/input/phone/phone.module";
import {NgOptimizedImage} from "@angular/common";

@Component({
    standalone: true,
    selector: "ma-ui-sms-installation",
    templateUrl: "./sms-installation.component.html",
    styleUrls: ["sms-installation.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [InputPhoneModule, NgOptimizedImage],
    providers: [FooterSubscribeService],
})
export class SmsInstallationComponent {
    public service = inject(FooterSubscribeService);
    public loader = signal(false);
    public form = new FormGroup({
        phone: new FormControl(""),
        submit: new FormControl(false),
    });

    onClick() {
        this.form.get("submit").setValue(true);
        if (this.form.valid) {
            this.loader.set(true);
            this.service
                .send(this.form.get("phone").value)
                .then(() => {
                    this.form.get("phone").setValue("");
                    this.loader.set(false);
                    this.form.reset();
                })
                .catch(() => {
                    this.loader.set(false);
                });
        }
    }
}
